import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const About = (props) => {
  return (
    <Layout bodyClass="page-about">
      <SEO title="About" />
      <div className="intro mt-5 mt-lg-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-6">
        <div className="row justify-content-start">
          <div className="col-12">
            <p>TSN Building Contractors Ltd (formerly of Notcon Builders &amp; Contractors Ltd) is a family run business with over 35 years of experience in construction and renovation of commercial and residential properties. Based in East London, we operate across South-East London and within Central London. A large percentage of our business has been generated by word of mouth due to the quality of our work and high level of customer satisfaction.</p>
            <p>We pride ourselves in providing reliable and professional services for all types of building projects by putting the customer first and working together to achieve their aspirations.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
